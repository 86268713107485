<template lang="pug">
  include ../plugins/pug/btn

  div.casespage.second__page
    div.custom__loader
    PageHeaderBlock(
      :header-img="WallCases"
      :header-title="'Cases'"
      :header-content="`Discover our impactful projects showcasing the power of Disoft IT solutions in driving innovation and results.`"
    ).cases__header

    section(v-if="combinedTag").section
      div.container
        div.directions
          h3.directions__title.color-orange Directions
          div.directions__dropdown
            div(@click="openDropdown").change__directions {{ directionValue }}
            ul.directions__list
              li(
                v-for="(tag, index) in combinedTag"
                :key="index"
              ).list__item
                a(
                  :class="{ active: selectedTag === tag }"
                  @click="toggleTag(tag)"
                  v-scroll-to="'#cases__list'"
                ).btn.btn__primary
                  span {{ tag }}

    section(v-if="casesList")#cases__list.section
      div.container
        div.cases.cases__preview
          div(
            v-for="(cards, index) in filteredCards"
            :key="index"
          ).cases__item.dialog__window
            div.dialog__window-top
              span
            div.dialog__window-content
              div.cases__body
                div.cases__content
                  h2(
                    v-if="cards.preview_title"
                    v-html="cards.preview_title"
                  ).h2.cases__title
                  ul(v-if="cards.add_directions").cases__tags
                    li(
                      v-for="(tag, index) in cards.add_directions"
                      :key="index"
                      :style="`border-color:${cards.color_scheme}`"
                    ).tags__item {{ tag }}
                div(v-if="cards.preview_image").custom__image
                  img(
                    :src="getImagePath(cards.preview_image)"
                    alt="cases image"
                  )
</template>

<script>
// images
import WallCases from '@/assets/icons/wall-cases.svg'
export default {
  name: 'CasesPage',
  components: {
    PageHeaderBlock: () => import('@/components/PageHeaderBlock')
  },
  data () {
    return {
      WallCases,
      casesList: [],
      selectedTag: null,
      directionValue: 'Change directions'
    }
  },
  computed: {
    combinedTag () {
      const directions = new Set()
      this.casesList.forEach(item => {
        item.add_directions.forEach(direction => {
          directions.add(direction)
        })
      })
      return Array.from(directions)
    },
    filteredCards () {
      if (!this.selectedTag) {
        return this.casesList
      } else {
        return this.casesList.filter(card => {
          return card.add_directions.includes(this.selectedTag)
        })
      }
    }
  },
  mounted () {
    this.fetchVacancies()
  },
  methods: {
    async fetchVacancies () {
      try {
        const response = await this.$api.get(`${ process.env.VUE_APP_API }/items/cases/?[_eq]=published`)
        this.casesList = response.data.data
      } catch (error) {
        console.error('Error:', error)
      }
    },
    toggleTag (tag) {
      document.querySelector('.directions__list').classList.remove('active')
      document.querySelector('.change__directions').classList.remove('active')
      if (this.selectedTag === tag) {
        this.selectedTag = null
        this.directionValue = null
      } else {
        this.selectedTag = tag
        this.directionValue = tag
      }
    },
    openDropdown () {
      if (document.documentElement.clientWidth <= 767) {
        document.querySelector('.directions__list').classList.toggle('active')
        document.querySelector('.change__directions').classList.toggle('active')
      }
    },
    getImagePath (image) {
      return `${ process.env.VUE_APP_API }/assets/${image}`
    }
  }
}
</script>
